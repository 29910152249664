<template>
  <div class="document-page fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="documentsTransactionsList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"/>
  </div>
</template>

<script>
import {getDocumentTransactions} from '@/http/requests/documents'
import {addComma} from '@/assets/js/functions'

export default {
  name: 'document',
  metaInfo () {
     return {
       title: this.$t('accountancy.documents.document.title')
     }
  },
  data () {
    return {
      loadingTimer: 0,
      options: {
        id: 'documentsTransactionsListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'credit',
          i18n: 'accountancy.documents.document.table.header.credit',
          width: '200px',
          minWidth: 200,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          color: 'success',
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'debt',
          i18n: 'accountancy.documents.document.table.header.debt',
          width: '200px',
          minWidth: 200,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          color: 'danger',
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'description',
          i18n: 'accountancy.documents.document.table.header.description',
          width: '100%',
          minWidth: 200,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'rowNumber',
          i18n: 'accountancy.documents.document.table.header.rowNumber',
          width: '80px',
          minWidth: 80,
          locked: true,
          align: 'center',
          sortable: true,
          footer: {
          }
        }
      ],
      data: [],
      page: 1,
      endedList: false,
      actions: [
        {
          /*toolbar: [
            {
              id: {name: 'insertDocument'},
              type: 'link',
              icon: 'icon-plus',
              iconPack: 'feather',
              color: 'success'
            }
          ],*/
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    this.getDocumentTransactions()
  },
  methods: {
    getDocumentTransactions () {
      setTimeout(() => {
        if (this.$refs.documentsTransactionsList && this.data.length === 0) this.$refs.documentsTransactionsList.loadMoreStatus = 'FirstLoad'
        else if (this.$refs.documentsTransactionsList && this.data.length > 0) this.$refs.documentsTransactionsList.loadMoreStatus = 'Loading'

        const docId = this.$route.params.docId || this.$route.params.id
        getDocumentTransactions(docId, 1, [], ['order[0]=price,asc']).then(response => {
          const transactions = response.data.data

          transactions.forEach((transaction) => {
            this.data.push({
              id: transaction.id,
              rowNumber: this.data.length + 1,
              description: `${transaction.account} ${transaction.reference ? ` - ${transaction.reference}` : ''}`,
              debt: {value: transaction.price < 0 ? Math.abs(transaction.price) : 0, type: 'price', classes: ['text-danger']},
              credit:  {value: transaction.price > 0 ? transaction.price : 0, type: 'price', classes: ['text-success']}
            })
          })

          const row_index = this.columnsLabel.map((e) => {
            return e.field
          }).indexOf('rowNumber')
          this.columnsLabel[row_index].footer.value = this.data.length

          if (this.$refs.documentsTransactionsList) this.$refs.documentsTransactionsList.loadMoreStatus = ''
        }).catch(error => {

          if (this.$refs.documentsTransactionsList) this.$refs.documentsTransactionsList.loadMoreStatus = 'Danger'
        })
      }, 500)
    }
  }
}
</script>

<style scoped>

</style>
